<template>
    <div>
        <b-card :title="`Welcome back, ${this.$store.state.auth.user.full_name} 🚀`">
            <b-card-text>Please make sure to read our <b-link href="https://oneposts.io" target="_blank">
                    Documentation
                </b-link> to understand where to go from here and how to use our tool.</b-card-text>
        </b-card>
        <b-overlay :show="loading" variant="transperant" blur="5px" opacity="1" spinner-variant="primary">
            <!-- Stats Card Horizontal -->
            <b-row id="links">
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="SendIcon" :statistic="data.posts"
                        statistic-title="POSTS" />
                </b-col>
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="MessageSquareIcon" color="success"
                        :statistic="data.comments" statistic-title="COMMENTS" />
                </b-col>
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="LinkIcon" color="danger"
                        :statistic="data.links" statistic-title="LINKS" />
                </b-col>
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="UserIcon" color="warning"
                        :statistic="data.profiles" statistic-title="SOCIAL PROFILES" />
                </b-col>
            </b-row>

            <b-row>
                <b-col md="4" sm="6">
                    <statistic-card-with-line-chart icon="AwardIcon" color="warning"
                        :statistic="data.brands" statistic-title="BRANDS"
                        :chart-data="[{ name: 'brands', data: [150, 200, 125, 225, 200, 250] }]" />
                </b-col>
                <b-col md="4" sm="6">
                    <statistic-card-with-line-chart icon="UsersIcon" :statistic="data.members"
                        statistic-title="MEMEBERS"
                        :chart-data="[{ name: 'memebers', data: [150, 200, 125, 225, 200, 250] }]" />
                </b-col>
                <b-col md="4" sm="6">
                    <statistic-card-with-line-chart icon="Share2Icon" color="success"
                        :statistic="data.channels" statistic-title="CHANNELS"
                        :chart-data="[{ name: 'channels', data: [150, 200, 125, 225, 200, 250] }]" />
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import { BCard, BCardText, BLink, BRow, BCol, BOverlay } from 'bootstrap-vue'
import axios from 'axios'

export default {
    components: {
        StatisticCardWithLineChart, StatisticCardHorizontal,
        BCard, BCardText, BLink, BRow, BCol, BOverlay,
    },
    data() {
        return {
            loading: true,
            data: {
                brands: 0,
                channels: 0,
                comments: 0,
                members: 0,
                posts: 0,
                links: 0,
                profiles: 0,
            }
        }
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        async getUserInfo() {
            await axios.get('/dashboard/')
                .then(res => {
                    this.loading = false
                    this.data = res.data
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }
}
</script>

<style></style>